import * as React from 'react';
import { navigate } from 'gatsby';

import { AppProps, SeoObject } from 'src/app/types';
import { Container, PrivateRoute, Seo } from 'src/app/components';

import { Router } from '@reach/router';

import { AdminType } from 'src/admin/constants';

import { AdminLoginContainer } from 'src/admin/components';
import { useAppContext, useLocalizationContext } from 'src/app/hooks';
import { SystemAdminApp } from 'src/app.system/components';

const basePath = '/admin/system';

const NotFound = () => {
  // const { language } = useLocalizationContext();
  // navigate(`/${language}`);
  return null;
};

const seo: SeoObject = {
  title: 'System Admin Page',
};

const SystemAdminAppPage = (props: AppProps) => {
  const { location, pageContext } = props;
  const { isLoggedIn, systemAdminRoles } = useAppContext();

  return (
    <div>
      {/* <CookieConsent /> // FIXME */}
      <Seo location={location} pageContext={pageContext} seo={seo} />
      <Router basepath={basePath}>
        {isLoggedIn && systemAdminRoles?.length ? (
          <PrivateRoute path='/*' component={SystemAdminApp} />
        ) : (
          <>
            {isLoggedIn ? (
              <NotFound path={'/*'} />
            ) : (
              <AdminLoginContainer
                path={'/*'}
                redirectAfterLoginTo={basePath}
                adminType={AdminType.SYSTEM}
              />
            )}
          </>
        )}
      </Router>
    </div>
  );
};

export default SystemAdminAppPage;
